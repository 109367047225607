<template>
  <div>
    <SmallNav />
    <div class="container-fluid">
      <div class="safe_text_holder">
        <h4 class="your_project">
          <img src="../assets/images/safe.svg" alt="safe" />
          <span @click="navigateBack"> Back</span>
        </h4>
        <div class="projects_link">
          <router-link to="/dashboard/autodebit">
            <h6 class="btn">
              <img src="../assets/images/addplus.svg" alt="projects" />
              Auto Debit
            </h6>
          </router-link>
        </div>
      </div>

      <div class="pb-4">
        <div class="empty__state" v-if="autoDebits.length === 0 && !loading">
          <div class="empty__div">
            <img src="../assets/images/empty-state.svg" alt="" />
            <p>Empty</p>
          </div>
        </div>
        <div v-if="loading">
          <PageLoader></PageLoader>
        </div>
        <div v-else class="pb-4">
          <!-- <p>{{ openClose }}</p> -->
          <!-- @click="openPanel(index)" -->
          <div
            class="transaction_holder"
            v-for="(data, index) in autoDebits"
            :key="index"
            @click="openCollaspTab(index)"
          >
            <div class="collapsible">
              <!-- <p>+</p> -->
              <h6>
                <div>
                  <div class="green-dot" v-if="data.status === 'green'"></div>
                  <div class="yellow-dot" v-if="data.status === 'yellow'"></div>
                  <div class="red-dot" v-if="data.status === 'red'"></div>
                </div>
                <span class="data_type">Auto Debit: {{ data.frequency }}</span>
              </h6>
              <h6>
                &#8358;{{ Number(data.auto_debit_amount).toLocaleString() }}
                <small
                  ><i
                    :name="index"
                    class=""
                    style="font-size: 20px;"
                    :class="[
                      openCollasp == index
                        ? 'fa fa-angle-up'
                        : 'fa fa-angle-down'
                    ]"
                  ></i
                ></small>
              </h6>
            </div>
            <div
              :id="index"
              :ref="index"
              class="content"
              :class="[openCollasp == index ? 'newContent' : 'removeContent']"
            >
              <div class="row content-body">
                <div class="col-md-4">
                  <h6>
                    <span>Auto debit amount: </span
                    >{{ Number(data.auto_debit_amount).toLocaleString() }}
                  </h6>
                </div>
                <div class="col-md-4">
                  <h6><span>Duration: </span>{{ data.duration }}</h6>
                </div>
                <div class="col-md-4">
                  <h6><span>Frequency: </span>{{ data.frequency }}</h6>
                </div>
                <div class="col-md-4">
                  <h6><span>Start date: </span>{{ data.startdate }}</h6>
                </div>
                <div class="col-md-4">
                  <h6><span>End date: </span>{{ data.enddate }}</h6>
                </div>
                <div class="col-md-4">
                  <h6>
                    <span>Projected Amount: </span
                    >{{ Number(data.projectedamount).toLocaleString() }}
                  </h6>
                </div>
              </div>
              <div class="disable-auto-debit">
                  <button class="btn" @click.stop="disableAutoDebit(data.id)">Disable auto debit</button>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          v-if="autoDebits"
          @pageData="dataFromChild($event)"
        ></Pagination>

        <div class="table-responsive d-none">
          <table class="table">
            <thead v-if="autoDebits.length != 0">
              <tr>
                <th style="text-align: center;">Status</th>
                <th>Debit Amount</th>
                <th>Duration</th>
                <th>Frequency</th>
                <th>Projected Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in autoDebits" :key="index">
              <tr>
                <th scope="row">
                  <div class="green-dot" v-if="item.status === 'green'"></div>
                  <div class="yellow-dot" v-if="item.status === 'yellow'"></div>
                  <div class="red-dot" v-if="item.status === 'red'"></div>
                </th>
                <td>₦{{ item.auto_debit_amount }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.frequency }}</td>
                <td>₦{{ item.projectedamount }}</td>
                <td><small class="details_text">Details</small></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="confirmDisable"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmDisable"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <p class="mt-3 info">Do you want to disable auto debit ?</p>
          </div>
          <div class="footer-btn">
            <button class="btn cancel" data-dismiss="modal">Cancel</button>
            <button :disabled="disabling" class="btn disable" @click="disableAutoDebitConfirmation()">Disable
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="disabling"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
import Pagination from "../components/Pagination.vue";

export default {
  name: "SafeAutoDebitHistory",
  // props: ["page"],
  components: {
    SmallNav,
    PageLoader,
    Pagination
  },
  data() {
    return {
      loading: false,
      disabling: false,
      openClose: false,
      openCollasp: -1,
      page: 1,
      limit: 10,
      totalcount: "",
      thePageNum: [],
      autoDebits: "",
      debitId: "",
    };
  },

  mounted() {
    // this.pageUrlGlobal();
    // this.dataFromChild();
    this.autoDebitHistory();
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    },
    openCollaspTab(i) {
      this.openCollasp = i;
      const d = document.getElementById(i);
      const c = document.getElementsByName(i);
      if (this.openCollasp == i) {
        if (d.classList.contains("newContent")) {
          d.classList.add("removeContent");
          d.classList.remove("newContent");
          // this.openClose = true;
        } else {
          d.classList.remove("removeContent");
          d.classList.add("newContent");
          // this.openClose = false;
        }
        if (c[0].classList.contains("fa-angle-up")) {
          c[0].classList.remove("fa-angle-up");
          c[0].classList.add("fa-angle-down");
        } else {
          c[0].classList.add("fa-angle-up");
          c[0].classList.remove("fa-angle-down");
        }
      } else {
        d.style.maxHeight = "0px !important";
      }
    },
    async autoDebitHistory() {
      this.loading = true;
      try {
        await Api.getRequest(
          `safeautodebithistory?page=${this.page}&perpage=${this.limit}`
        )
          .then(res => {
            // console.log("Auto debit", res);
            this.loading = false;
            if (res.data.success) {
              this.totalcount = res.data.totalpages;
              this.pageUrlGlobal();
              this.autoDebits = res.data.auto_debit_history;
              return this.$store.commit("setApiSuccess", "Auto debit loaded");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      } finally {
        // console.log("closed");
      }
    },
     disableAutoDebit(id) {
      this.debitId = id;
      $("#confirmDisable")
      .addClass("fade")
      .modal("show");
    },
    async disableAutoDebitConfirmation() {
      this.disabling = true;
      try {
        Api.postRequest(`stopsafeautodebit`, {id: this.debitId}).then((res) => {
          // console.log("disabled auato debit", res);
          this.disabling = false;
          if (res.data.success) {
            this.$store.commit("setApiSuccess", "Success");
            $("#confirmDisable")
            .removeClass("fade")
            .modal("hide");
          } else {
            this.$store.commit("setApiFailed", "Error occured");
          }
        }).catch(err => {
          console.log(err);
          this.disabling = false;
          this.$store.commit("setApiFailed", "Error occured");
        });
      } finally {
        // console.log("closdd")
      }
    },
    dataFromChild(data) {
      // console.log("data from child", data);
      this.autoDebits = data.data.auto_debit_history;
    },
    pageUrlGlobal() {
      Api.pushPageURL(`safeautodebithistory`);
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$offblack: #262626;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.safe_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
  margin-top: 30px;
}

.your_project {
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    cursor: pointer;
  }
}

.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }
}

thead tr {
  background: $secondary;
  th {
    border-bottom: none !important;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 211%;
    color: $offblack;
    text-align: left;
    white-space: nowrap;
  }
}
tbody tr {
  background: $secondary;
  th {
    border-top: 1px solid #f1f1f1 !important;
  }
  td {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offblack;
    text-align: left;
  }
}

.details_text {
  border: 1px solid $primary;
  border-radius: 4px;
  color: $primary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  // line-height: 125%;
  padding: 4px 6px;
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
  margin-top: 3px;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffb100;
  margin: auto;
  margin-top: 3px;
}
.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
  margin-top: 3px;
}

.empty__div {
  padding: 1rem;
  img {
    width: 100%;
    max-width: 470.58px;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    margin-bottom: 0;
  }
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: #000000;
  }
}

// Collapsable begins
.transaction_holder {
  cursor: pointer;
  background: $secondary;
  height: fit-content;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  // border-radius: 10px;
  // margin-bottom: 10px;
  .collapsible {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px;
    padding: 15px;
    .data_type {
      padding-left: 15px;
      font-weight: 200;
      color: #2e2e2e;
    }
    // p:first-child {
    //   font-weight: bold;
    // }
    p {
      text-align: start;
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
    h6 {
      text-align: start;
      font-family: Gotham-bold;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
  }
  // .collapsible:hover {
  //   background-color: #e2e2e2 !important;
  // }
  small {
    padding-left: 20px;
  }
}

.disable-auto-debit {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
  button {
    border: 1px solid #b3b8b8;
    background: transparent;
    color: #b3b8b8;
    font-family: Gotham;
    font-size: 14px;
    box-shadow: none;
    outline: 0;
  }
}

.newContent {
  height: fit-content !important;
  transition: max-height 0.3s ease-out;
}
.removeContent {
  height: 0 !important;
  transition: max-height 0.3s ease-out;
}

.content {
  padding: 0 18px;
  padding-left: 30px;
  height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #f8f9fa;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
}

.content-body {
  h6 {
    padding: 10px 15px;
    // padding-left: 30px;
    text-align: left;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $black;
    span {
      color: #8e8e8e;
    }
  }
}

.info {
  font-family: Gotham;
  font-size: 15px;
}

.footer-btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  button {
    background: transparent;
    font-family: Gotham;
    font-size: 14px;
    box-shadow: none;
    outline: 0;
  }
  .cancel {
    border: 1px solid #ea0000;
    color: #ea0000;
  }
  .disable {
    border: 1px solid $primary;
    color: $primary;
  }
}

// Collapsable ends

// New Accordion begin
// New accordion end

@media only screen and (max-width: 599px) {
  thead tr {
    th {
      line-height: 125% !important;
    }
  }
  // empty state
  .empty__div {
    p:nth-child(3) {
      font-size: 13px;
    }
  }
  .transaction_holder {
    small {
      padding-left: 10px;
    }
  }
}
</style>
